.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.book_now {
  border: 1px solid #37a08c;
  background: #88c9b1;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    background: #88c9b1;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #37a08c;
  }
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #1b2021be;
  border-radius: 50%;
  width: 40px !important;
  aspect-ratio: 1/1;
  &:after {
    font-size: 0.8rem !important;
    color: #37a08c;
    font-weight: 800;
  }
  &:hover {
    background-color: #37a08c;
    &:after {
      color: #fff;
    }
  }

  // &.swiper-button-disabled {
  //   display: none;
  // }
  &:active {
    background-color: #37a08c;
    &:after {
      color: #fff;
    }
  }
}

.data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h1 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: #37a08c;
    @media (width<=768px) {
      font-size: 1.9rem;
      line-height: 2.5rem;
    }
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: #37a08c;
    @media (width<=768px) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
  p,
  ul,
  li {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8rem;

    @media (width<=768px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  ul,
  li {
    list-style-type: decimal;
  }
  ul {
    padding: 0 0 0 2.5rem;
  }
}
